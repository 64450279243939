.login-container {

    height: 100vh;
    background-size: 100% 100%;
    width: 100%;
    background: url(https://static-redcliffelabs.s3.amazonaws.com/media/gallary-file/None/c008e64e-e699-4b3a-9f5f-cc65caa68a6b.jpeg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    display: flex;
    align-items: center; }

.mainHeading {
    font-size: 2rem;
    margin-left: 0;
    width: 44%;
    color: #010101;
    font-weight: 300;
    font-weight: 500;
    color: #162e70; }
