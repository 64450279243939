.main-file-upload {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0;

    .file-uploader {
        display: flex;
        align-items: center; } }
