.head {
    background: rgb(116, 39, 114) !important;
    padding: 1rem;
    font-size: clamp(1.2rem, 3vw, 1.4rem);
    color: white;
    text-align: center; }

.details {
    font-size: clamp(0.8rem, 3vw, 1rem);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

.total {
    font-size: clamp(1rem, 3vw, 1.2rem);
    background: #eee !important;
    padding: 1rem;
    margin: 0;
    color: black !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
        font-weight: bold;
        font-size: clamp(1rem, 3vw, 1.2rem); } }

span {
    font-weight: bold;
    font-size: clamp(0.8rem, 3vw, 1rem); }
