.input {
    .MuiSelect-root {
        padding: 18.5px 14px !important; }
    input {
        color: black !important;
        padding: 18.5px 14px !important; } }

.sampleCollectionTimeFamily {
    padding: 0.5rem 1rem;
    overflow: auto;
    border-radius: 5px;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    max-height: 350px;
    p {
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: white; } }
