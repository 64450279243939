.MuiPagination-ul {
    margin: 0;
    display: flex;
    padding: 0;
    flex-wrap: inherit;
    list-style: none;
    align-items: center; }
.data-table {
    svg {
        cursor: pointer; }
    .MuiTableCell-root {
        padding: 4px 4px!important; }
    th,td {
        font-size: 13px!important;
        min-width:8rem !important {} } }
