.MuiOutlinedInput-input {
    padding: none!important; }

.MuiOutlinedInput-input {
    padding: 3px 11px!important;


    .inline {
        display: flex!important; } }
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0!important; }
