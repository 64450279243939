.MuiGrid-container {
    width: 100% !important; }

.sampleCollectionTime {
    padding: 0.5rem 1rem;
    overflow: auto;
    border-radius: 5px;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    max-height: 200px;
    p {
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: white; } }
.sampleCollectionTimeImaging {
    padding: 0.5rem 1rem;
    overflow: auto;
    border-radius: 5px;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    height: 100%;
    max-height: 390px;
    p {
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: white; } }
.sampleCollectionTimeNewBooking {
    padding: 0.5rem 1rem;
    overflow: auto;
    border-radius: 5px;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    height: 100%;
    max-height: 470px;
    p {
        margin: 0;
        text-align: center;
        font-size: 1.2rem;
        font-weight: bold;
        color: white; } }

.priceDetails {
    margin: 1rem auto;
    height: 100%;
    max-height: 400px;
    padding: 2rem 1.5rem;
    overflow: auto;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    border-radius: 5px;
    .totalPrice {
        text-align: center;
        font-weight: normal;
        width: 100%;
        color: white;
        border: 1px solid white !important;
        padding: 0.5rem;
        margin: 0; }
    table {
        padding: 0.5rem;
        border: 1px solid white;
        color: white;
        thead {
            font-size: 1.2rem; } }
    .cell {
        padding: 1rem;
        border: 1px solid white !important;
        text-transform: capitalize!important; } }

.price-details-new-booking {
    margin: 1rem auto;
    height: 100%;
    max-height: 500px;
    min-height: 500px;
    padding: 20px 20px 20px 20px;
    overflow: auto;
    background: linear-gradient(120deg, #5983e8, #00e4d0);
    border-radius: 5px;
    .totalPrice {
        font-weight: normal;
        color: #fff;
        padding: 5px 5px 5px 5px;
        font-size: 20px; }
    table {
        padding: 0.5rem;
        border: 1px solid white;
        color: white;
        thead {
            font-size: 1.2rem; } }
    .cell {
        padding: 1rem;
        border: 1px solid white !important; } }


.input {
    .MuiSelect-root {
        padding: 18.5px 14px !important; }
    input {
        color: black !important;
        padding: 18.5px 14px !important; } }

.css-yk16xz-control {
  padding: 8px 8px 8px 6px; }

.pac-container {
  z-index: 10000 !important; }


